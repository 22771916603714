.home {
  width: 90%;
  margin: auto;
  display: block;

  h1,
  h2,
  p {
    margin-bottom: 0.5rem;
  }
}

.home-section {
  padding: 1rem 0;
  text-align: center;
  border-bottom: 2px solid #7b6953;
  margin-bottom: 1rem;

  h2 {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }
}

.home-upcoming {
  margin: auto;
  width: 80%;
  padding-bottom: 1rem;

  p {
    padding-bottom: 1rem;
    border-bottom: 2px solid #7b6953;
  }

  p:last-of-type {
    border-bottom: none;
  }

  a {
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: #7b6953;
    }
  }

  ul {
    list-style: none;
  
    li {
      margin-left: 1rem;
      margin-top: 0.5rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .home {
    display: block;
  }

  .home-section-container {
    display: flex;
    border-bottom: 2px solid #7b6953;
    margin-bottom: 1rem;
  }

  .home-section {
    width: 50%;
    border: none;
  }

}

@media only screen and (min-width: 1200px) {
  .home {
    display: flex;
    max-width: 1400px;
    padding: 2rem 0;
  }

  .home-section-container {
    width: 75%;
    border-bottom: none;
  }

  .home-upcoming {
    width: 25%;
    padding-top: 1rem;
  }
}