.chronicles {
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 95%;
  padding: 1rem;

  h1 {
    margin-bottom: 0.5rem;
  }

  h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
  }

  h2,
  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;

    & :hover {
      color: #7b6953;
    } 

    small {
      width: 25%;
    }

    h3 {
      width: 75%;
    }
  }

  .chronicle-item {
    max-width: 900px;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #7b6953;

  }
  
}

@media only screen and (min-width: 768px) {
  .chronicles {
    width: 85%;

    h3 {
      font-size: 1.1rem;
    }

    a {
      small {
        width: 15%;
      }

      h3 {
        width: 85%;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .chronicles {
    width: 80%;
    max-width: 1200px;

    a {
      small {
        width: auto;
        margin-right: 1rem;
      }

      h3 {
        width: auto;
      }
    }
  }
}