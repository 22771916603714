.author-intro {
  display: block;
  justify-content: center;
  align-items: center;

  .img-container {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
  
  }

  .author-text {
    width: 85%;
    margin: auto;

    h1 {
      margin: 1rem 0;
    }

    h2 {
      margin-top: 1rem;
    }

    h3 {
      margin-top: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }

    h3:last-of-type {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;

      &:hover {
        color: #7b6953;
      }
    }
  }
}


@media only screen and (min-width: 992px) {
  .author-intro {
    display: flex;
    justify-content: center;
    align-items: center;
  
    .img-container {
      width: 30%;
      margin: 1rem;
    }
  
    .author-text {
      width: 70%;
      margin: 1rem;
    }
  }
}