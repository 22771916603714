.science {
  width: 90%;
  margin: auto;
  padding: 1rem 0;

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: none;
  }

  .book-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;

    .img-container {
      width: 15%;

      img {
        width: 100%;
      }
    }

    .text-container {
      width: 85%;
      padding: 1rem;
    }
  }
  
}

@media only screen and (min-width: 768px) {
  .science {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) {
  .science {
    width: 70%;
    max-width: 1200px;

    h2 {
      margin-bottom: 1rem;
    }

    .book-container {

      .img-container {
        width: 10%;
      }

      .text-container {
        width: 90%;
      }
    }
  } 
}