.upcoming {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  padding: 1rem 0;

  h1,
  p {
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    border-top: 2px solid #7b6953;
    padding-top: 1rem;
  }

  a {
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: #7b6953;
    }
  }

  ul {
    list-style: none;
  
    li {
      margin-left: 1rem;
      margin-top: 0.5rem;
    }
  }

  .past,
  .past strong,
  .past a {
    color: #646464;
  }
}