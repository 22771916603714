.books {
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;

  h1 {
    margin: 1rem 0;
    text-align: center;
  }

  section {
    display: block;

    .img-container {
      margin: auto;
      width: 90%;
      margin-bottom: 1rem;
    }

    .book-desc {
      width: 100%;
      margin: auto;

      p {
        margin-bottom: 1rem;
      }

      a {
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: #7b6953;
        }
      }

      .order {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
      }

      .logo-img {
        width: 20%;

        img {
          width: 100%;
          border-radius: 8px;
        }

        a {
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .bok2 {
    margin-top: 2rem;
    border-top: 2px solid #7b6953;

    .img-container {
      margin-top: 1rem;
    }

    // .book-desc {
    //   .order {
    //     justify-content: flex-start;

    //     .logo-img {
    //       margin-left: 1rem;
    //     }
    //   }
    // }
    
  }
}

@media only screen and (min-width: 768px) {
  .books {
    width: 90%;

    section {
      display: flex;
      align-items: center;

      .img-container {
        width: 45%;
        margin-bottom: auto;
        margin-top: 1rem;
      }

      .book-desc {
        width: 55%;
        margin-left: 1rem;
      }
    }

    .bok2 {
      padding: 2rem 0;
    }
  }
}

@media only screen and (min-width: 992px) {
  .books {
    width: 80%;
    max-width: 1200px;

    h1 {
      text-align: center;
      margin: 1rem 0;
    }

    section {
      display: flex;

      .img-container {
        width: 30%;
      }

      .book-desc {
        width: 70%;
        padding: 1rem;
      }
    }
  }
}