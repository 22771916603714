@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');
@import './navbar';
@import './home';
@import './footer';
@import './author';
@import './chronicles';
@import './books';
@import './science';
@import './upcoming';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #322722;
}
html {
  background-color: #322722;
}

#App {
  background-color: #eaeaea;
}

h1,
h2 {
  font-family: 'Quicksand', sans-serif;
}

h1 {
  font-size: 1.8em;
}

.img-container {
  margin: auto;
  width: 50%;

  img {
    width: 100%;
    border-radius: 8px;
  }
}


